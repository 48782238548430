// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UUV6P_9L_zl0JS_9zJqf {\n  display: inline-flex;\n  align-items: center;\n  margin: 5px;\n  padding: 5px 10px;\n  border-radius: 20px;\n  cursor: pointer;\n  position: relative;\n  top: -44px;\n}\n\n.reL_dYDoLGDvXwJhwqJs {\n  border: \"1px solid #38d39f\";\n  color: \"#38d39f\";\n  background-color: \"#e6f4ea\";\n}\n\n.zlXZxoS74QKfAwy3kM8i {\n  border: \"1px solid #b19cd9\";\n  color: \"#b19cd9\";\n  background-color: \"#f4eaff\";\n}\n\n.es0n__YZJbBpGgL72EIn {\n  border: \"1px solid #c0c0c0\";\n  color: \"#c0c0c0\";\n  background-color: \"#f4f4f4\";\n}\n\n._6QP2llv1AV9X8c4UOIY {\n  height: 0 !important;\n}\n\n.J9iMwydrESaMXSrQSic7 {\n  margin-left: 5px;\n  cursor: pointer;\n}\n\n.J9iMwydrESaMXSrQSic7:hover {\n  color: red;\n}\n\n.Xjr7QkqLmrKJyt95c_dX {\n  display: none;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selected-option": "UUV6P_9L_zl0JS_9zJqf",
	"connected": "reL_dYDoLGDvXwJhwqJs",
	"not-connected-available": "zlXZxoS74QKfAwy3kM8i",
	"not-connected-unavailable": "es0n__YZJbBpGgL72EIn",
	"selected-options-wrapper": "_6QP2llv1AV9X8c4UOIY",
	"remove-option": "J9iMwydrESaMXSrQSic7",
	"no-show": "Xjr7QkqLmrKJyt95c_dX"
};
export default ___CSS_LOADER_EXPORT___;
